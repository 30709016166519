@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Satoshi;
  src: url('../public/fonts/Satoshi-Regular.woff2');
}

@font-face {
  font-family: Satoshi-Medium;
  src: url('../public/fonts/Satoshi-Medium.woff2');
}

@font-face {
  font-family: Satoshi-Bold;
  src: url('../public/fonts/Satoshi-Bold.woff2');
}
